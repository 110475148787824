@import "shared";

$mamzelle-top__height: 43vh;
$mamzelle-text__shadow: 0px 1px 18px rgba(99, 19, 44, 50%);
$mamzelle-socialmedia-ico__width: 45px;

#animCanvas {
  background-position: center bottom;
}

#canvasWrapper {
  position: relative;
  z-index: 2;
}

.mamzelle-logo__container {
  min-height: $mobile-100vh;
  height:100%;
  width: $mobile-100vw;
  background-color: $dark-pink;
  overflow: hidden;
}
.mamzelle-logo__top {
  position: relative;
  min-height: $mamzelle-top__height;
  height: $mamzelle-top__height;
  background-color: $dark-pink;
  overflow: hidden;

  &__background {
    opacity:0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;

    /*! background pattern from www.subtlepatterns.com and adapted from me  */

    &.background1 {
      background-image: url('/images/background/pattern1.jpg');
    }

    &.background2 {
      background-image: url('/images/background/pattern2.jpg');
    }

    &.background3 {
      background-image: url('/images/background/pattern3.jpg');
    }

    &.background4 {
      background-image: url('/images/background/pattern4.jpg');
    }

    &.background5 {
      background-image: url('/images/background/pattern5.jpg');
    }

    &.background6 {
      background-image: url('/images/background/pattern6.jpg');
    }

    &.background7 {
      background-image: url('/images/background/pattern7.jpg');
    }
  }

  &__shadow {
    position: absolute;
    width: 100%;
    z-index: 3;
    bottom:0;
    content: "";
    display: block;
    height: pxToRem(30);
    opacity:0 ;
    background: linear-gradient(to bottom, rgba(83,0,26, 0), rgba(83,0,26, 1));
  }
}

.mamzelle-logo__bottom {
  opacity: 0;
  height: 100%;
  min-height: calc(#{$mobile-100vh} - #{$mamzelle-top__height});
  background-color: $medium-pink;
  padding: pxToRem(46) 0;
  transform: translateY(calc(#{$mobile-100vh} - #{$mamzelle-top__height + 30vh}));
  overflow: hidden;

  @include media('small', true) {
    padding: pxToRem(36) 0;
  }

  &__inner {
    opacity:0;
  }
}


.mamzelle-description__title,
.mamzelle-description__jobtitle {
  font-family: $font-primary;
  text-transform: uppercase;
  color: $light-white;
  text-align: center;
  width: 100%;
}

.mamzelle-logo {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  filter: drop-shadow($mamzelle-text__shadow);
  max-width: 480px;
}

.mamzelle-logo-media-item__link__container {
  margin-top: pxToRem(60);
  margin-bottom:0;
  padding-left: 0;
  text-align: center;

  @include media('small-xs', true) {
    margin-top: pxToRem(30);
    transform: scale(0.9);
  }
}

.mamzelle-logo-media-item__link__inner {
  list-style: none;
  display: inline-block;
  margin-right: pxToRem(10);

  &:last-child {
    margin-right: 0;
  }
}

.mamzelle-logo-media-item__link {
  display:inline-block;
  position: relative;

  &:hover {
    .mamzelle-logo-media-item__outline {
      opacity: 0;
    }

    .mamzelle-logo-media-item__reg {
      opacity: 1;
    }
  }
}


.mamzelle-logo-media-item__reg {
}

.mamzelle-logo-media-item__outline,
.mamzelle-logo-media-item__reg {
  width: $mamzelle-socialmedia-ico__width;
  transition: opacity 0.5s ease-in-out;
}

.mamzelle-logo-media-item__reg {
  position: absolute;
  top:0;
  left: 0;
  opacity: 0;
}




@media screen and (max-width: 499px) and (max-height:800px) {
  .mamzelle-logo__top {
    height: 280px;
  }
}

@media screen and (min-width: 500px)  and (max-width: 839px) and (max-height:800px) {
  .mamzelle-logo__top {
    height: 220px;
  }
}

@media screen and (min-width: 840px) and (max-height:800px) {
  .mamzelle-logo__top {
    min-height: 340px;
  }
}


@media screen and (max-width: map-get($breakpoints, small)) {
  .mamzelle-logo {
    max-width: 380px;
    width: 70%
  }

  .mamzelle-logo-media-item__link__container {
    margin-top: pxToRem(30);
    transform: scale(0.9);
  }
}

@media screen and (min-width: map-get($breakpoints, small)) and (max-width: map-get($breakpoints, medium)) {
  .mamzelle-logo {
    max-width: 330px;
  }

  .mamzelle-logo-media-item__link__container {
    margin-top: pxToRem(40);
  }
}

