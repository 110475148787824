
* {
  box-sizing: border-box;
}

body {
  background:#3a1228;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
}

#stageCanvas {
  position:absolute;
  top:0px;
  left:0px;
  border: none;
}
#canvasBck {
  position:absolute;
  top:0px;
  left:0px;
  border: none;
}

#canvasWrapper {
  width:100%;
  height: 100%;
}

