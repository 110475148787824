
/* -------------------------------------------------------------------------------------
Breakpoints
------------------------------------------------------------------------------------- */

$breakpoints: (
        small-xxs : 360px,
        small-xs  : 480px,
        small     : 600px,
        medium    : 840px,
        medium-l  : 960px,
        medium-xl : 1024px,
        large     : 1280px,
        large-xl  : 1600px,
        large-xxl : 1920px
);


// media queries helper (mobile first)
// --
// eg: @include media("medium"){...} )
// set $not-mobile-first param to true for inverting mobile first logic when necessary
// eg: @include media("large", true){...} )


@mixin media($breakpoint, $not-mobile-first: false) {

  @if map-has-key($breakpoints, $breakpoint) {

    @if($not-mobile-first) {
      @media screen and (max-width: map-get($breakpoints, $breakpoint) - 1px) {
        @content
      }
    }
    @else {
      @media screen and (min-width: map-get($breakpoints, $breakpoint)) {
        @content
      }
    }

  } @else {
    @error "Invalid breakpoint name: `#{$breakpoint}`.";
  }

}
